(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendPost = void 0;
const EmailJSResponseStatus_1 = require("../models/EmailJSResponseStatus");
const store_1 = require("../store/store");
const sendPost = (url, data, headers = {}) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('load', ({ target }) => {
            const responseStatus = new EmailJSResponseStatus_1.EmailJSResponseStatus(target);
            if (responseStatus.status === 200 || responseStatus.text === 'OK') {
                resolve(responseStatus);
            }
            else {
                reject(responseStatus);
            }
        });
        xhr.addEventListener('error', ({ target }) => {
            reject(new EmailJSResponseStatus_1.EmailJSResponseStatus(target));
        });
        xhr.open('POST', store_1.store._origin + url, true);
        Object.keys(headers).forEach((key) => {
            xhr.setRequestHeader(key, headers[key]);
        });
        xhr.send(data);
    });
};
exports.sendPost = sendPost;

},{"../models/EmailJSResponseStatus":6,"../store/store":7}],2:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendForm = exports.send = exports.init = void 0;
const init_1 = require("./methods/init/init");
Object.defineProperty(exports, "init", { enumerable: true, get: function () { return init_1.init; } });
const send_1 = require("./methods/send/send");
Object.defineProperty(exports, "send", { enumerable: true, get: function () { return send_1.send; } });
const sendForm_1 = require("./methods/sendForm/sendForm");
Object.defineProperty(exports, "sendForm", { enumerable: true, get: function () { return sendForm_1.sendForm; } });
exports.default = {
    init: init_1.init,
    send: send_1.send,
    sendForm: sendForm_1.sendForm,
};

},{"./methods/init/init":3,"./methods/send/send":5,"./methods/sendForm/sendForm":4}],3:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
const store_1 = require("../../store/store");
/**
 * Initiation
 * @param {string} userID - set the EmailJS user ID
 * @param {string} origin - set the EmailJS origin
 */
const init = (userID, origin = 'https://api.emailjs.com') => {
    store_1.store._userID = userID;
    store_1.store._origin = origin;
};
exports.init = init;

},{"../../store/store":7}],4:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendForm = void 0;
const store_1 = require("../../store/store");
const validateParams_1 = require("../../utils/validateParams");
const sendPost_1 = require("../../api/sendPost");
const findHTMLForm = (form) => {
    let currentForm;
    if (typeof form === 'string') {
        currentForm = document.querySelector(form);
    }
    else {
        currentForm = form;
    }
    if (!currentForm || currentForm.nodeName !== 'FORM') {
        throw 'The 3rd parameter is expected to be the HTML form element or the style selector of form';
    }
    return currentForm;
};
/**
 * Send a form the specific EmailJS service
 * @param {string} serviceID - the EmailJS service ID
 * @param {string} templateID - the EmailJS template ID
 * @param {string | HTMLFormElement} form - the form element or selector
 * @param {string} userID - the EmailJS user ID
 * @returns {Promise<EmailJSResponseStatus>}
 */
const sendForm = (serviceID, templateID, form, userID) => {
    const uID = userID || store_1.store._userID;
    const currentForm = findHTMLForm(form);
    validateParams_1.validateParams(uID, serviceID, templateID);
    const formData = new FormData(currentForm);
    formData.append('lib_version', '3.2.0');
    formData.append('service_id', serviceID);
    formData.append('template_id', templateID);
    formData.append('user_id', uID);
    return sendPost_1.sendPost('/api/v1.0/email/send-form', formData);
};
exports.sendForm = sendForm;

},{"../../api/sendPost":1,"../../store/store":7,"../../utils/validateParams":8}],5:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.send = void 0;
const store_1 = require("../../store/store");
const validateParams_1 = require("../../utils/validateParams");
const sendPost_1 = require("../../api/sendPost");
/**
 * Send a template to the specific EmailJS service
 * @param {string} serviceID - the EmailJS service ID
 * @param {string} templateID - the EmailJS template ID
 * @param {object} templatePrams - the template params, what will be set to the EmailJS template
 * @param {string} userID - the EmailJS user ID
 * @returns {Promise<EmailJSResponseStatus>}
 */
const send = (serviceID, templateID, templatePrams, userID) => {
    const uID = userID || store_1.store._userID;
    validateParams_1.validateParams(uID, serviceID, templateID);
    const params = {
        lib_version: '3.2.0',
        user_id: uID,
        service_id: serviceID,
        template_id: templateID,
        template_params: templatePrams,
    };
    return sendPost_1.sendPost('/api/v1.0/email/send', JSON.stringify(params), {
        'Content-type': 'application/json',
    });
};
exports.send = send;

},{"../../api/sendPost":1,"../../store/store":7,"../../utils/validateParams":8}],6:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailJSResponseStatus = void 0;
class EmailJSResponseStatus {
    constructor(httpResponse) {
        this.status = httpResponse.status;
        this.text = httpResponse.responseText;
    }
}
exports.EmailJSResponseStatus = EmailJSResponseStatus;

},{}],7:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
exports.store = {
    _origin: 'https://api.emailjs.com',
};

},{}],8:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateParams = void 0;
const validateParams = (userID, serviceID, templateID) => {
    if (!userID) {
        throw 'The user ID is required. Visit https://dashboard.emailjs.com/admin/integration';
    }
    if (!serviceID) {
        throw 'The service ID is required. Visit https://dashboard.emailjs.com/admin';
    }
    if (!templateID) {
        throw 'The template ID is required. Visit https://dashboard.emailjs.com/admin/templates';
    }
    return true;
};
exports.validateParams = validateParams;

},{}],9:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initEmail = void 0;
const emailjs_com_1 = require("emailjs-com");
emailjs_com_1.init("user_R1UO7zkQn9IkFmSpudSnW");
const myForm = document.querySelector("form");
const button = document.querySelector(".email-send");
const loader = document.querySelector(".loader");
function initEmail() {
    button.addEventListener("click", (e) => {
        myForm.style.display = "none";
        loader.style.display = "block";
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha
                .execute("6LcNAbEdAAAAABpkFzI6QqnacoTQpqTUbY-i-lUF", {
                action: "submit",
            })
                .then(function (token) {
                var input = document.createElement("input");
                input.setAttribute("type", "hidden");
                input.setAttribute("name", "g-recaptcha-response");
                input.setAttribute("value", token);
                myForm.append(input);
                // Add your logic to submit to your backend server here.
                emailjs_com_1.sendForm("service_1rsve0j", "template_m0rgnrs", myForm).then(function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                }, function (error) {
                    console.log("FAILED...", error);
                });
            });
        });
    });
}
exports.initEmail = initEmail;
},{"emailjs-com":2}],10:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const main_navigation_1 = require("./main_navigation");
const email_1 = require("./email");
const modal_1 = require("./modal");
main_navigation_1.initMenu();
email_1.initEmail();
const modalOutlet = document.querySelector(".modal-container");
const galleries = document.querySelectorAll(".lightgallery");
galleries.forEach((gallery) => {
    const images = Array.from(gallery.children).map((gal) => gal.firstElementChild.lastElementChild);
    images.forEach((image, index) => {
        const modal = new modal_1.Modal(modalOutlet);
        image.addEventListener("click", () => {
            modal.showModal(images, image, index);
        });
    });
});
},{"./email":9,"./main_navigation":11,"./modal":12}],11:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initMenu = void 0;
const button = document.querySelector(".js-main_navigation_mobile-button");
const menu = document.querySelector(".js-main-navigation_menu");
const toggleClass = () => {
    menu.classList.toggle("main-navigation_menu--open");
};
function initMenu() {
    button.addEventListener("click", toggleClass);
    menu.addEventListener("click", toggleClass);
}
exports.initMenu = initMenu;
},{}],12:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
class Modal {
    constructor(modalOutlet) {
        this.modalOutlet = modalOutlet;
        this.nav = document.querySelector(".main-navigation");
        this.showIndex = 0;
    }
    showModal(arr, image, index) {
        this.images = arr;
        this.showIndex = index;
        this.modalOutlet.innerHTML = this.markup();
        this.nav.style.display = "none";
        this.setElements();
        this.setShareImageLink(image);
        this.clone = image.cloneNode(true);
        this.content.appendChild(this.clone);
        this.updateText();
        this.addDownloadLink(image);
        this.addListeners();
    }
    setElements() {
        this.content = this.modalOutlet.querySelector(".modal-content");
        this.close = this.modalOutlet.querySelector(".close");
        this.download = this.modalOutlet.querySelector(".download > a");
        this.span = this.modalOutlet.querySelector("span");
        this.rightArrow = this.modalOutlet.querySelector(".right");
        this.leftArrow = this.modalOutlet.querySelector(".left");
    }
    addListeners() {
        this.close.addEventListener("click", this.closeModal.bind(this));
        this.rightArrow.addEventListener("click", this.nextImage.bind(this));
        this.leftArrow.addEventListener("click", this.prevImage.bind(this));
    }
    nextImage() {
        this.showImage(+1);
        this.updateText();
    }
    prevImage() {
        this.showImage(-1);
        this.updateText();
    }
    showImage(val) {
        this.content.removeChild(this.clone);
        this.setIndex(val);
        this.clone = this.images[this.showIndex].cloneNode(true);
        this.download.setAttribute("href", this.addLink(this.clone));
        this.setShareImageLink(this.clone);
        this.content.appendChild(this.clone);
    }
    setIndex(val) {
        this.showIndex += val;
        if (this.showIndex === -1) {
            this.showIndex = this.images.length - 1;
        }
        if (this.showIndex === this.images.length) {
            this.showIndex = 0;
        }
    }
    addDownloadLink(image) {
        this.download.setAttribute("href", this.addLink(image));
    }
    updateText() {
        this.span.innerHTML = `${this.showIndex + 1} / ${this.images.length}`;
    }
    closeModal() {
        this.modalOutlet.innerHTML = "";
        this.nav.style.display = "block";
    }
    addLink(image) {
        return image.getAttribute("data-src");
    }
    setShareImageLink(image) {
        this.shareLink = this.modalOutlet.querySelector("share-buttons");
        this.shareLink.setAttribute("img-src", this.addLink(image));
    }
    markup() {
        return `<div class="modal">
      <div class="modal-toolbar">
        <span class="text">4/4</span>
        <div class="icons">
          <share-buttons></share-buttons>  
          <button class="download">
            <a download href="">
              <i class="fas fa-chevron-down fa-2x icon"></i>
            </a>
          </button>
          <button class="close">
            <i class="fas fa-times fa-2x icon"></i>
          </button>
        </div>
      </div>

      <div class="modal-content">
        <button class="arrow left">
          <i class="fas fa-arrow-left fa-2x icon"></i>
        </button>
        <button class="arrow right">
          <i class="fas fa-arrow-right fa-2x icon"></i>
        </button>
      </div>
    </div>`;
    }
}
exports.Modal = Modal;
},{}],13:[function(require,module,exports){
class ShareButtons extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.innerHTML = `
        <button class="share">
            <i class="fab fa-facebook-square fa-2x icon"></i>
        </button>
        `;
        this.querySelector(".share").addEventListener("click", () => {
            this.imgSrc = this.getAttribute("img-src");
            const u = "http://stolkidrewniane.pl/" + this.imgSrc;
            window.open("http://www.facebook.com/sharer.php?u=" + encodeURIComponent(u), "sharer", "toolbar=0,status=0,width=626,height=436");
        });
    }
}
customElements.define("share-buttons", ShareButtons);
},{}]},{},[10,13])

